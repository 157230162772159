var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"body",staticStyle:{"padding-bottom":"5rem"}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 blue justify-end d-flex px-4 py-1"},[_c('div',{staticClass:"d-flex justify-end mb-1 rounded"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"tel: +254720244744"}},[_c('div',{staticClass:"mr-1 px-2  rounded blue black--text lighten-4"},[_c('v-icon',{staticClass:"black--text",attrs:{"small":""}},[_vm._v(" mdi-phone ")]),_vm._v(" 0720244744 ")],1)]),_c('div',{staticClass:"mr-1 px-2  rounded blue black--text lighten-4"},[_c('v-icon',{staticClass:"black--text",attrs:{"small":""}},[_vm._v(" mdi-mail ")]),_vm._v(" info@adilirealestate.com ")],1)])])]),_c('v-app-bar',{staticClass:"app-bar",staticStyle:{"z-index":"920","margin-top":"2rem","border-bottom":"solid #2196F3 1px","background-color":"white"},attrs:{"dark":"","flat":"","app":""}},[_c('div',[_c('v-img',{staticClass:"white pa-2 my-h2 rounded pointer",attrs:{"src":require("./assets/logo.png"),"max-height":"60","max-width":"150","contain":""},on:{"click":function($event){return _vm.go('/')}}})],1),_c('v-spacer'),_c('section',{staticClass:"d-flex align-end",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'Home',
        'red lighten-2': _vm.page_name == 'Home'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('home')}}},[_vm._v(" Home ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'About',
        'red lighten-2': _vm.page_name == 'About'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('about')}}},[_vm._v(" About Us ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'Properties',
        'red lighten-2': _vm.page_name == 'Properties'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('properties')}}},[_vm._v(" Properties ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'howToBuy',
        'red lighten-2': _vm.page_name == 'howToBuy'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('how-to-buy')}}},[_vm._v(" How To Buy ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'faqs',
        'red lighten-2': _vm.page_name == 'faqs'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('faqs')}}},[_vm._v(" FAQ ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'blogs',
        'red lighten-2': _vm.page_name == 'blogs'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('blogs')}}},[_vm._v(" Blogs ")]),_c('v-btn',{staticClass:"bold white--text mx-2",class:{
        'blue lighten-2': _vm.page_name != 'contact',
        'red lighten-2': _vm.page_name == 'contact'
      },attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goToSection('contact')}}},[_vm._v(" Contact Us ")])],1)],1),_c('v-main',{staticClass:"main",staticStyle:{"overflow-y":"auto","overflow-x":"hidden","height":"100vh","margin-bottom":"5rem"}},[_c('router-view'),_c('footer-strip'),_c('alert-box')],1),(_vm.getInfoBoxDetails && _vm.getInfoBoxDetails.status)?_c('info-box'):_vm._e(),_c('div',{staticStyle:{"position":"fixed","bottom":"1rem","left":"1rem","z-index":"50"}},[_c('v-icon',{staticClass:"mx-1 white--text success pa-3 rounded",on:{"click":_vm.goWhatsapp}},[_vm._v(" mdi-whatsapp ")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"tel: +254720244744"}},[_c('v-icon',{staticClass:"mx-1 white--text success pa-3 rounded"},[_vm._v(" mdi-phone ")])],1),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"sms: 0720244744"}},[_c('v-icon',{staticClass:"mx-1 white--text success pa-3 rounded"},[_vm._v(" mdi-message ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }